import React from 'react';
import { graphql } from 'gatsby';

import * as Core from 'components/core';
import Layout from 'components/departments/DepartmentLayout';

interface Props {
  data: {
    europeEntrance: Core.ImageAttributes;
  };
}

const DepartmentContent: React.FC<Props> = (props) => {
  const { europeEntrance } = props.data;

  return (
    <Layout departmentSlug="philanthropy" title="Philanthropy">
      <Core.ContentWrapper>
        <Core.DepartmentLabel>Philanthropy</Core.DepartmentLabel>
        <Core.Flag>Shape the World</Core.Flag>

        <Core.ContentSection>
          <Core.PrimaryHeading>
            SAIS Europe Receives Largest Gift in its History for Its 65th
            Anniversary
          </Core.PrimaryHeading>
          <Core.LargeText fontStyle="sans">
            Thanks to the generosity of James Anderson — Johns Hopkins trustee,
            Bologna Advisory Council chair, and alumnus — SAIS Europe has
            received an unprecedented $10 million gift.
          </Core.LargeText>
          <p>
            The gift not only benefits the school directly but also offers up to{' '}
            <Core.BoldText>$4 million in matching funds</Core.BoldText> to
            encourage renewed and new philanthropy toward critical areas for
            SAIS Europe.
          </p>
          <p>
            Areas covered include faculty (in particular, for the expansion of
            the existing Kenneth H. Keller Professorship and the C. Grove Haines
            Chair, which will support current Bologna faculty members), students
            (fellowships, internships, and co-curricular experiential
            activities, such as study trips and career treks), integration and
            new programming (online teaching, new one-year programs, faculty
            exchanges between Johns Hopkins SAIS campuses and Johns Hopkins’
            main campus in Baltimore), and the COVID-19 Emergency Relief Fund
            (to support those in the SAIS Europe community with financial needs,
            expanded technology to enhance virtual delivery of courses and
            seminars, and other resources necessary to mitigate the COVID-19
            pandemic).
          </p>
          <p>
            “This extraordinary gift celebrates the 65th anniversary of the
            founding of SAIS Europe and provides the center a unique opportunity
            to recover from this challenging year and look forward with
            optimism,” says Director Michael G. Plummer. “This is a call to
            action to the many Bolognesi in the world, and we hope that their
            response will be equally overwhelming.”
            <br />
            <br />
            <i>
              To learn more about the matching opportunities, please contact the{' '}
              <a
                target="_blank"
                rel="noreferrer"
                href="mailto:sais.eu.development@jhu.edu"
              >
                Office of Development
              </a>
              .
            </i>
          </p>
        </Core.ContentSection>

        <Core.FullWidthImage
          image={europeEntrance}
          alt="SAIS Europe Entrance"
        />

        <Core.HorizontalRule />

        <Core.ContentSection>
          <Core.PrimaryHeading>
            Robert J. Abernethy Professorship at SAIS Europe
          </Core.PrimaryHeading>
          <Core.LargeText fontStyle="sans">
            On June 23, 2020, Johns Hopkins University President Ronald Daniels
            announced the establishment of the Robert J. Abernethy Professorship
            at SAIS Europe.
          </Core.LargeText>
          <p>
            The announcement came on the occasion of a public virtual lecture
            hosted by SAIS Europe with Romano Prodi, former president of the
            European Commission, on “The World Economy after Covid-19.”
          </p>
          <p>
            The professorship honors Abernethy, a Johns Hopkins emeritus trustee
            and member of the Johns Hopkins SAIS Board of Advisors and SAIS
            Europe Advisory Council. With this new professorship, the
            second-largest gift in Abernethy’s lifetime to one of the Johns
            Hopkins schools, Abernethy intends to support teaching and research
            in global affairs with particular regard to the development of
            democratic institutions and constitutional studies around the world.
          </p>
          <p>
            The professorship will be held by Prodi for a two-year period
            starting in fall 2020. Prodi is a former president of the European
            Commission (1999–2004) and former prime minister of Italy (1996–98;
            2006–08). Prodi is a longtime friend of SAIS Europe and an honorary
            member of the Bologna Advisory Council.
          </p>
          <p>
            Thanks to Abernethy’s generosity, students at SAIS Europe will
            benefit from Prodi’s extensive expertise in international affairs
            and as a world leader. With the launch of the new{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://sais.jhu.edu/mepp"
            >
              Master of Arts in European Public Policy
            </a>{' '}
            at SAIS Europe, Prodi’s presence on the faculty “will certainly
            provide great leadership and prestige to the new program and to its
            students,” says{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://sais.jhu.edu/users/mleigh2"
            >
              MEPP Director Michael Leigh
            </a>
            .
          </p>
        </Core.ContentSection>

        <Core.HorizontalRule />

        <Core.ContentSection>
          <Core.PrimaryHeading>
            Stepping Up to Support Students during COVID-19
          </Core.PrimaryHeading>
          <Core.LargeText fontStyle="sans">
            It was mid-January when Richard Nijkerk (MA ’89, International
            Economics and Japan Studies) started hearing about a highly
            contagious new virus coming out of Wuhan, the capital of China’s
            Hubei Province.
          </Core.LargeText>
          <p>
            “We were in Dubai for the Chinese New Year, and people were already
            scared. You could feel it everywhere,” says Nijkerk, a member of the
            SAIS Dean’s Advisory Board who lives with his wife in Singapore,
            where he manages the Asia operations of his family’s industrial IT
            company.
          </p>
          <p>
            In early February, Singapore went into lockdown, with gatherings
            banned and masks mandatory. Offices and nonessential businesses
            moved to “work from home.” So when Nijkerk traveled to New York
            later that month for a Dean’s Advisory Board meeting, he remembers
            “being the grim person at the table. Everybody else was more
            optimistic about how the pandemic would affect the U.S. But the
            [SAIS] administration understood the gravity, given that SAIS Europe
            and the Nanjing centers already had closed, and they committed to
            immediately creating a contingency plan. Three weeks later, in
            mid-March, we got the announcement that the DC campus would shut
            down, effective immediately.”
          </p>
          <p>
            Nijkerk’s wife and two children were in the U.S. at the time and
            promptly returned to Singapore. In the process, Nijkerk thought
            about the SAIS students who might not have the financial resources
            to cope with sudden dislocation.
          </p>
          <p>
            “I thought of unexpected moving expenses coinciding with lost jobs,
            lost family income, so I reached out to the board’s chair and vice
            chair, Todd Fisher and Kathy Pike, and it was literally just a
            couple of emails and the board immediately stepped up and created
            the COVID-19 Student Emergency Fund.”
          </p>
          <p>
            Noemi Crespo Rice, assistant dean of student affairs, was assigned
            the job of managing the fund. It meant immediately creating a set of
            guidelines and a simple but fair application process, plus
            organizing a committee of six other SAIS administrators who would
            meet weekly to evaluate the applications and get the funds to
            students as soon as possible.
          </p>
          <p>
            “Because we have campuses in China and Italy, the COVID-19 crisis
            hit us much earlier than most other American institutions,” says
            Crespo Rice. “By the end of February, we had already closed both our
            Nanjing and Bologna campuses. Most students chose to flee
            immediately to wherever they were from, many leaving behind all of
            their belongings.”
          </p>
          <p>
            Students were frantic for help. Many had incurred debt paying for
            last-minute plane tickets and moving expenses, some had lost
            internet access, some were running out of money for rent and
            groceries.
          </p>
          <p>
            “Our students are so special, and some of their stories have been
            heartbreaking,” Crespo Rice says. “I find myself weeping with them
            on the phone. But the advisory board and the many other donors who
            stepped up have been amazing. We have awarded more than $120,000 to
            more than 140 students since March.”
          </p>
          <p>
            The average grant of nearly $1,000 has helped students recover
            belongings from the campuses they left, helped them pay for gas and
            groceries, and even for internet access to continue their studies
            online. One Nigerian student was able to graduate on time in May
            only because the fund helped cover in-home child care and rent when
            DC child care centers and her family’s business in Nigeria were
            shuttered by the pandemic.
          </p>
          <p>
            Because of the continued uncertainty and subsequent economic fallout
            for students, SAIS plans to continue raising money for and managing
            the emergency fund through the 2020–21 academic year.
          </p>
          <p>
            “Dean <Core.BoldText>Eliot Cohen</Core.BoldText> has committed a
            significant chunk of his discretionary fund in a matching effort to
            raise more money,” Crespo Rice says. “The students are so grateful,
            and it just warms your heart. We’re just hoping that five years from
            now, they can reflect back on this time and say, ‘Yes, it was
            difficult, but SAIS had my back,’ and that this fund was just one of
            many tools we used to show them we care about them.”
          </p>
          <p>
            Nijkerk says he is gratified by how quickly SAIS rose to the
            occasion. “It’s a great tribute to our board and administration,” he
            says. “In unprecedented times, one needs to act without hesitation.”{' '}
            <i>Joan Katherine Cramer</i>
          </p>
        </Core.ContentSection>
      </Core.ContentWrapper>
    </Layout>
  );
};

export const query = graphql`
  query($directory: String!) {
    europeEntrance: file(
      relativePath: { regex: $directory }
      name: { eq: "SAIS-Europe-Entrance" }
    ) {
      ...FullWidthImage
    }
  }
`;

export default DepartmentContent;
